<template>
  <div class="card">
    <div class="card-header">
      <div>
        <div class="card-title">{{ $t('page.settings.hashtags.title') }}</div>
        <small>{{ $t('page.settings.hashtags.description') }}</small>
      </div>
    </div>
    <div class="card-body flex flex-col gap-4">
      <div
        v-for="(item , index) in form.hashtags" 
        :key="index"
      >
        <p class="font-semibold mb-2">
          {{ item.name }}
          <span
            v-if="item.default"
            class="badge capitalize text-xs text-white bg-green-500 rounded-full py-1 ml-4"
            style="display: inline;"
          >
          <i class="far fa-check-circle text-base text-white"></i> {{ $t('page.settings.hashtags.isDefault') }}
          </span>
        </p>
        <div class="flex gap-2">
          <div
            v-for="hashtag in item.hashtags" :key="hashtag"
            class="rounded w-10 min-w-max h-6 p-4 flex justify-center items-center text-gray-600 border border-gray-400"
          >
            {{ hashtag }}
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer flex flex-row flex-wrap justify-end items-center">
      <ProfileHashtagsSettingsModal :form="form" @update:form="handleUpdate" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch} from 'vue'
import ProfileHashtagsSettingsModal from './ProfileHashtagsSettingsModal.vue'

export default defineComponent({
  components: {
    ProfileHashtagsSettingsModal
  },

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    
    const profile = {
      hashtags: props.form.hashtags
    }

    watch(profile, (newValue) => {
      emit('update:form', newValue)
    }, { deep: true })

    const handleUpdate = (data: any) => {
      emit('update:form', data)
    }

    return {
      profile,
      handleUpdate
    }
  }
})
</script>
