<template>
  <base-modal
    v-model:open="isModalActive"
    hide-footer
  >
    <template v-slot:button>
      <button
        type="button"
        class="btn btn-primary sm:text-sm"
        >
        {{ $t('action.edit') }}
      </button>
    </template>

    <div class="card-header">
      <div>
        <div class="card-title">
          {{ $t('page.settings.hashtags.title') }}
        </div>
        <small>
          {{ $t('page.settings.hashtags.subtitle') }}
        </small>
      </div>
    </div>

    <div class="card-body">
      <div>
        <hashtags-group 
          v-for="(item , index) in form.hashtags" 
          :key="index" 
          :titleProp="item.name" 
          :hashtagsProp="item.hashtags"
          :defaultProp="item.default"
          :id="index"
          :hashtag="item"
          @update-title="updateTitle"
          @update-hashtags="updateHashtags"
          @update-default="updateDefault"
          @delete-group="deleteGroup(index)"
        />
      </div>
    </div>

    <div class="card-footer flex justify-between">
      <button
        @click="newGroup()"
        class="btn btn-secondary outline"
      >
        <i class="fas fa-plus mr-2 text-white"></i>
        {{ $t('page.settings.hashtags.new_group')}}
      </button>
      <button class="btn btn-primary" @click.prevent="saveForm">
        {{ $t('action.finish') }}
      </button>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent, ref , computed, watchEffect, watch} from 'vue'
import BaseModal from '../base/BaseModal.vue'
import HashtagsGroup from './HashtagsGroup.vue'
import { useToggle } from '@/composables/useToggle'
import { useDebounceFn } from '@vueuse/core'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'

type group = {
  default: boolean, 
  hashtags: Array<String>,
  name: string
}

export default defineComponent({
  components: {
    BaseModal,
    HashtagsGroup,
  },

  props:{
    form: {
      type: Object,
      required: true
    }
  },

  emits: [
    'update:form'
  ],

  setup (props ,{emit}) {
    const profile = ref<any>({
      hashtags: props.form.hashtags,
    })

    const {
      isActive: isModalActive,
      setInactive: closeModal
    } = useToggle(false)

    const {t} = useI18n()

    const postHashtags = ref('')

    const formBuffer = ref(JSON.parse(JSON.stringify(profile.value)))

    const updateTitle = ({id , value}: {id: number, value: string}) => {
      profile.value.hashtags[id].name = value
    }

    const updateHashtags = ({id , hashtags}: {id: number, hashtags: string[]}) => {
      profile.value.hashtags[id].hashtags = hashtags
    }

    const defaultGroups = computed(() => {
      const filterDefaultGroup = profile.value.hashtags.filter((group: group) => group.default == true);
      const hashtagsGroupArray: Array<string> = [];
      filterDefaultGroup.forEach(function(group: any){
        hashtagsGroupArray.push(group.hashtags)
      })
      let joiningHashtags: string[] = [];
      Array.from(hashtagsGroupArray).forEach(element => {
        joiningHashtags = joiningHashtags.concat(Array.from(element))
      });
      return joiningHashtags
    })

    const makeFirstAsDefault = () => {
      const defaultGroup = profile.value.hashtags.find((hashtagsGroup: { default: boolean }) => hashtagsGroup.default === true)
      if (!defaultGroup) {
        profile.value.hashtags[0].default = true
      }
    }

    const deleteGroup = (index: number) => {
      if (profile.value.hashtags.length > 1) {
        Swal.fire({
          title: t('state.remove_hashtag_group.title'),
          text: t('state.remove_hashtag_group.message'),
          showCancelButton: true,
          confirmButtonText: t('state.remove_hashtag_group.action_confirm'),
          cancelButtonText: t('state.remove_hashtag_group.action_cancel')
        }).then(async ({ value }) => {
          if (value) {
            profile.value.hashtags.splice(index, 1)
            makeFirstAsDefault()
          }
        })
      } else {
        Swal.fire({
          confirmButtonText: t('state.remove_unique_hashtag_group.action_confirm'),
          text: t('state.remove_unique_hashtag_group.message'),
        })
      }
    }

    const updateDefault = (id: number) => {
      profile.value.hashtags.forEach((hashtagsGroup: { default: boolean }, index: number) => {
        hashtagsGroup.default = index === id
      })
    }

    const newGroup = () => {
      const group = { name: t('page.settings.hashtags.newGroupName'), hashtags: [], default: false }
      profile.value.hashtags.push(group)
    }

    watchEffect(() => {
      if (!profile.value.hashtags?.length) {
        newGroup()
      }
    })

    const onEditPostHashtags = useDebounceFn(() => {
      const splitArray = postHashtags.value
        .replace(/\s+/g, ' ')
        .split(' ')
        .map((hashtag: string) => {
          return `#${hashtag.replaceAll('#', '')}`
        })
      
      postHashtags.value = Array
        .from([...new Set(splitArray)])
        .slice(0,30)
        .join(' ')
    },500)

    const saveForm = async () => {
      formBuffer.value = JSON.parse(JSON.stringify(profile.value))

      emit('update:form', profile.value)

      closeModal()
    }

    const resetForm = () => {
      profile.value = JSON.parse(JSON.stringify(formBuffer.value))
      closeModal()
    }

    watch(isModalActive, (newValue) => {
      if (newValue === false) {
        resetForm()
      }
    })

    return {
      saveForm,
      updateTitle,
      updateHashtags,
      updateDefault,
      newGroup,
      defaultGroups,
      postHashtags,
      onEditPostHashtags,
      deleteGroup,
      isModalActive,
      closeModal
    }
  }
})
</script>
