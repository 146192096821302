
import { defineComponent, watch} from 'vue'
import ProfileHashtagsSettingsModal from './ProfileHashtagsSettingsModal.vue'

export default defineComponent({
  components: {
    ProfileHashtagsSettingsModal
  },

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    
    const profile = {
      hashtags: props.form.hashtags
    }

    watch(profile, (newValue) => {
      emit('update:form', newValue)
    }, { deep: true })

    const handleUpdate = (data: any) => {
      emit('update:form', data)
    }

    return {
      profile,
      handleUpdate
    }
  }
})
